import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-scroll";
import hambMenu from "../assets/hamb-menu.png";

const Navbar = () => {
  const [isHamburgerOpen, setHamburgerState] = useState(false);
  return (
    <nav className='navbar'>
      <div
        className='navbar-hamburger'
        onClick={() => setHamburgerState(!isHamburgerOpen)}>
        <img
          className='navbar-hamburger-icon'
          src={hambMenu}
          width={"35"}
          alt={"hamburger-menu"}
        />
      </div>
      <div
        className={
          isHamburgerOpen ? "navbar-links-active" : "navbar-links"
        }>
        <Link
          className='nav-item'
          smooth
          to='Home'
          onClick={() => setHamburgerState(!isHamburgerOpen)}>
          Home
        </Link>
        <Link
          className='nav-item'
          smooth
          to='Services'
          onClick={() => setHamburgerState(!isHamburgerOpen)}>
          Servicios
        </Link>
        <Link
          className='nav-item'
          smooth
          to='About'
          onClick={() => setHamburgerState(!isHamburgerOpen)}>
          Sobre nosotros
        </Link>
        <Link
          className='nav-item'
          smooth
          to='Contact'
          onClick={() => setHamburgerState(!isHamburgerOpen)}>
          Contacto
        </Link>
        {/* Dropdown para idiomas o simplemente un enlace si no es interactivo */}
        {/* <div className='nav-item dropdown'>
          ES <span className='dropdown-arrow'>▼</span>
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;

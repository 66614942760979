import React from "react";
import "./Header.css";
import whiteLogo from "../assets/white-logo.png";
import Navbar from "../Navbar/Navbar";

const Header = () => {
  return (
    <header className='header'>
      <div className='header-logo'>
        <img className='header-logo-img' src={whiteLogo} alt='logo' />
        <p className='header-logo-title'>ACSSET VIICOR</p>
      </div>
      <Navbar />
    </header>
  );
};

export default Header;

import React from "react";
import "./About.css";
import excelencia from "../assets/excelencia.png";
import eficiencia from "../assets/eficiencia.png";
import atc from "../assets/atc.png";

// Individual feature component
const Feature = ({ title, description, icon }) => (
  <div className='feature'>
    <img class='feature-img' src={icon} alt='img' />
    <div className='feature-info'>
      <h3 className='feature-title'>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

// Main about us screen component
const About = () => (
  <div className='about' id='About'>
    <div className='about-header'>
      <p>Sobre nosotros</p>
    </div>
    <div className='about-features'>
      <Feature
        icon={excelencia}
        title='Excelencia'
        description='En ACSSET VIICOR, la excelencia es nuestro estándar. Con un equipo de expertos altamente cualificados, nos dedicamos a entregar soluciones tecnológicas superiores que superen sus expectativas. Nuestro compromiso con la calidad garantiza soluciones innovadoras y efectivas, impulsando su negocio hacia el éxito.'
      />
      <hr />

      <Feature
        icon={eficiencia}
        title='Eficiencia'
        description='Nuestra enfoque en ACSSET VIICOR es la eficiencia. Integrando tecnologías avanzadas y prácticas optimizadas, maximizamos la productividad de su empresa. Reducimos costos y tiempo, garantizando que cada proyecto se ejecute de manera ágil y efectiva, entregando resultados tangibles y rápidos.'
      />
      <hr />

      <Feature
        icon={atc}
        title='Atención al cliente'
        description='La atención al cliente en ACSSET VIICOR va más allá del soporte técnico. Nos esforzamos por entender las necesidades específicas de cada cliente, ofreciendo soluciones personalizadas y un acompañamiento constante. Su satisfacción es nuestra prioridad, y estamos comprometidos a asegurar su éxito continuo.'
      />
    </div>
  </div>
);

export default About;

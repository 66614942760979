import React, { useState } from "react";
import "./Contact.css";
import whiteLogo from "../assets/white-logo.png";
import mail from "../assets/mail.png";
import phone from "../assets/telefono.png";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const INITIAL_STATE = {
    name: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [isSent, setIsSent] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_SERVICES_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_PUBLIC_KEY
    );

    setFormData(INITIAL_STATE);
    setIsSent(true);
  };

  return (
    <div className='contact-container' id='Contact'>
      <div className='contact-info'>
        <div className='contact-logo'>
          <img
            className='contact-logo-img'
            src={whiteLogo}
            alt='logo'
          />
          <h2>ACSSET VIICOR</h2>
        </div>
        <div className='contact-data'>
          <div className='contact-data-mail'>
            <img class='contact-data-mail-img' src={mail} alt='img' />
            <p>Email: consulting@assetviicor.es</p>
          </div>
          <div className='contact-data-phone'>
            <img class='contact-data-phone-img' src={phone} alt='img' />
            <p>Phone: +34 633 194272</p>
          </div>
          <div className='contact-media'>
            <p>Connect with us on Social Media:</p>
            <p>Instagram | Twitter | Facebook | LinkedIn</p>
          </div>
        </div>
      </div>
      <div className='contact-form'>
        <p className='contact-form-title'>Formulario de Contacto:</p>
        <form className='contact' onSubmit={handleSubmit}>
          <label>
            <input
              type='text'
              name='name'
              placeholder='Nombre y apellidos'
              value={formData.name}
              onChange={handleChange}
            />
          </label>
          <label>
            <input
              type='email'
              name='email'
              required
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>
            <textarea
              name='message'
              required
              placeholder='Mensaje'
              value={formData.message}
              onChange={handleChange}
            />
          </label>
          <button type='submit' disabled={isSent ? true : false}>
            {isSent ? "Gracias!" : "Enviar"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;

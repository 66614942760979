import React from "react";
import "./App.css";
import Intro from "./Intro/Intro";
import Header from "./Header/Header";
import Services from "./Services/Services";
import About from "./About/About";
import Contact from "./Contact/Contact";

function App() {
  return (
    <div className='App'>
      <Header />
      <section id='Home'>
        <Intro />
      </section>
      <section id='Services'>
        <Services />
      </section>
      <section id='About'>
        <About />
      </section>
      <section id='Contact'>
        <Contact />
      </section>
    </div>
  );
}

export default App;

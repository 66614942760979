import React from "react";
import "./Intro.css";
import whiteLogo from "../assets/white-logo.png";
import { Link } from "react-scroll";

const Intro = () => {
  return (
    <div className='intro' id='Home'>
      <img class='intro-bg' src={whiteLogo} alt='logo' />
      <h1 className='intro-title'>{"Web, App & Marketing"}</h1>
      <p className='intro-description'>
        Ofrecemos las mejores soluciones tecnológicas para tu negocio
      </p>
      <Link to='Contact' smooth>
        <button className='intro-btn'>Contacto</button>
      </Link>
    </div>
  );
};

export default Intro;

import React from "react";
import "./Services.css";
import web from "../assets/web.png";
import app from "../assets/app.png";
import mkt from "../assets/mkt.png";

// Individual service box component
const ServiceBox = ({ img, title, description }) => (
  <div className='service-box'>
    <img class='service-box-img' src={img} alt='img' />
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

// Main services screen component
const Services = () => (
  <div className='services' id='Services'>
    <h1>Nuestros servicios</h1>
    <div className='services-container'>
      <ServiceBox
        img={web}
        title='Web'
        description='Página Web 100% personalizada y adaptada a dispositivos móviles.'
      />
      <ServiceBox
        img={app}
        title='App'
        description='La App que quieras como tú quieras. Disponible iOS y Android.'
      />
      <ServiceBox
        img={mkt}
        title='Marketing'
        description='Lleva a tu negocio a lo más alto con nuestros planes de marketing'
      />
    </div>
  </div>
);

export default Services;
